import { useState, useEffect } from 'react';
import { 
  Box,
  Button, 
  Group,
  Stack,
  Text,
  Paper,
  Timeline,
  Loader,
  Modal
} from '@mantine/core';
import { IconThumbUp, IconThumbDown, IconMessage, IconPhone, IconClipboardList } from '@tabler/icons-react';
import { useMedplum } from '@medplum/react';
import { formatDistanceToNow } from 'date-fns';
import { Communication, Task, Reference, Patient, Encounter } from '@medplum/fhirtypes';
import { FeedbackForm } from './FeedbackForm';
import { getInteractionDescription, Interaction, InteractionType, getInteractionIcon } from './types';

interface FeedbackFormProps {
  onSubmit?: () => void;
}

interface FeedbackState {
  rating: string;
  category: string;
  details: string;
  selectedInteractions: Set<string>;
}

export function FeedbackPage(): JSX.Element {
  const medplum = useMedplum();
  const profile = medplum.getProfile();
  const patientId = profile?.id;
  
  const [loading, setLoading] = useState(true);
  const [recentInteractions, setRecentInteractions] = useState<Interaction[]>([]);
  const [selectedInteraction, setSelectedInteraction] = useState<Interaction | null>(null);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  useEffect(() => {
    const fetchRecentInteractions = async () => {
      try {

        // Fetch recent encounters (calls)
        const encounters = await medplum.searchResources('Encounter', {
          patient: `Patient/${patientId}`,
          _sort: '-date',
          _count: '8'
        });

        // Fetch recent communications (messages)
        const communications = await medplum.searchResources('Communication', {
          subject: `Patient/${patientId}`,
          _sort: '-sent',
          'sender:not': `Patient/${patientId}`,
          _count: '8'
        });

        // Fetch recent tasks
        const tasks = await medplum.searchResources('Task', {
          subject: `Patient/${patientId}`,
          _sort: '-authored-on',
          _count: '8'
        });

       // Process encounters (calls)
        const encounterInteractions: Interaction[] = encounters.map(encounter => {
            return {
            id: encounter.id ?? '',
            type: 'call',
            description: encounter.reasonCode?.[0]?.text ?? 'Phone Call',
            timestamp: encounter.meta?.lastUpdated || '', // Changed to || to only fallback to empty string
            resourceType: 'Encounter'
            };
        });
        
        // Process communications (messages)
        const communicationInteractions: Interaction[] = communications
            .filter(comm => !comm.encounter)
            .map(comm => {
            return {
                id: comm.id ?? '',
                type: 'message',
                description: comm.payload?.[0]?.contentString ?? 'Message',
                timestamp: comm.meta?.lastUpdated || '', // Changed to || to only fallback to empty string
                resourceType: 'Communication'
            };
            });
        
        // Process tasks
        const taskInteractions: Interaction[] = tasks.map(task => {
            return {
            id: task.id ?? '',
            type: 'task',
            description: task.description ?? 'Task',
            timestamp: task.meta?.lastUpdated || '', // Changed to || to only fallback to empty string
            resourceType: 'Task'
            };
        });

        // Combine, sort, and limit interactions
        const allInteractions = [...encounterInteractions, ...communicationInteractions, ...taskInteractions]
          .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
          .slice(0, 5);

        setRecentInteractions(allInteractions);
      } catch (error) {
        console.error('Failed to fetch recent interactions:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchRecentInteractions();
  }, [medplum]);

  // Update the Interaction type to include title
  const processInteraction = (interaction: Interaction): Interaction => {
    const description = interaction.description.length > 50 
      ? interaction.description.substring(0, 50) + '...'
      : interaction.description;
      
    const title = (() => {
      switch (interaction.type) {
        case 'call':
          return `Phone Call: ${description}`;
        case 'message':
          return `Message: ${description}`;
        case 'task':
          return `Task: ${description}`;
      }
    })();
  
    return {
      ...interaction,
      title
    };
  };
  


  return (
    <Box>
      <Paper shadow="xs" p="md" withBorder>
        <Stack gap="md">
          <Text size="xl" fw={700}>Recent Interactions</Text>
          
          {loading ? (
            <Group justify="center" py="xl">
              <Loader size="md" />
            </Group>
          ) : (
            <Timeline active={-1} bulletSize={24}>
              {recentInteractions.map((interaction) => (
                <Timeline.Item
                  key={interaction.id}
                  bullet={getInteractionIcon(interaction.type)}
                  title={
                    <Group gap="xs" align="center">
                      <Text>{getInteractionDescription(interaction)}<Text size="xs" c="dimmed">
                        {formatDistanceToNow(new Date(interaction.timestamp))} ago
                      </Text></Text>
                      

                    </Group>
                  }
                >
                  
                  <Button 
                        variant="light" 
                        size="xs"
                        onClick={() => {
                          setSelectedInteraction(interaction);
                          setShowFeedbackModal(true);
                        }}
                      >
                        Provide Feedback
                      </Button>
                </Timeline.Item>
              ))}
            </Timeline>
          )}
        </Stack>
      </Paper>

      <Modal 
        opened={showFeedbackModal} 
        onClose={() => {
          setShowFeedbackModal(false);
          setSelectedInteraction(null);
        }}
        title="Provide Feedback"
        size="lg"
      >
        <FeedbackForm 
    selectedInteraction={selectedInteraction || undefined}
    onSubmit={() => {
      setShowFeedbackModal(false);
      setSelectedInteraction(null);
    }} 
  />
      </Modal>
    </Box>
  );
}